import React, { memo } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { renderFirstName } from '~helpers/common';
import moment from 'moment';

import VerificationBadge from '~components/verification/badge';
import Avatar from '~components/avatar';
import Stars from '~components/stars';
import LastOnline from '~components/last-online';
import Button from '~components/button';
import Favorite from '~components/favorite';
import Tooltip from '~components/tooltip';

import '../style.scss';

const WorkerCard = memo(
  ({
    personId,
    className,
    score,
    price,
    address,
    experience,
    firstName,
    profilePicture,
    verification,
    lastLogin,
    isOnline,
    personalNote,
    days,
    published,
    showViewButton = false,
    showCloseButton = false,
    onClose = () => {},
    showFavorite = false,
    isFavorite,
    onView = () => {},
    onFavorite = () => {},
  }) => {
    const intl = useIntl();

    const truncatedDescription = personalNote
      ? truncate(personalNote, {
          length: 300,
          separator: ' ',
        })
      : '-';

    const fName = renderFirstName(firstName);

    return (
      <div className={cx('c-card c-card--worker', { [className]: !!className })} itemType="http://schema.org/Product">
        <meta itemProp="brand" content="Juan Pescador" />
        <meta itemProp="sku" content={personId} />
        <meta itemProp="name" content={fName} />
        {profilePicture && profilePicture.large ? <link itemProp="image" href={profilePicture.large} /> : null}
        <meta
          itemProp="description"
          content={
            personalNote ||
            intl.formatMessage(
              {
                id: 'containers.profile.seo.worker.description.default',
              },
              {
                name: fName,
                city: address?.city || address?.country,
              }
            )
          }
        />
        <div className={`c-card__inner ${!published ? 'c-card__inner_not_active' : ''}`} itemProp="offers" itemType="http://schema.org/Offer" itemScope>
          <div className="c-card__header">
            <Avatar profilePicture={profilePicture} firstName={fName} className="c-card__photo" size="thumbnail" />
            <div className="c-card__header-details">
              <div className="c-card__name l-truncate notranslate">
                {fName}
                <VerificationBadge isVerified />   
              </div>
              {/* {price && parseInt(price.amount, 10) >= 0 && price.currency ? (
                <div className="c-card__price">
                  {intl.formatMessage(
                    { id: 'components.card.price.value', defaultMessage: '{amount}' },
                    {
                      amount: intl.formatNumber(price.amount / 100, {
                        style: 'currency',
                        currency: price.currency,
                      }),
                    }
                  )}
                </div>
              ) : null} */}
            </div>
            {showFavorite && (
              <Favorite
                personId={personId}
                accountType="worker"
                isFavorite={isFavorite}
                mode="icon"
                onChange={onFavorite}
                className="c-card__favorite"
              />
            )}
            {showCloseButton && (
              <button type="button" className="c-card__close" onClick={onClose}>
                <FiX />
              </button>
            )}
          </div>
          <div className="c-card__content">
            {/* SCORE */}
            <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.reviews', defaultMessage: 'Customer rating' })}
              </div>
              <div className="c-card__value c-card__score">
                <Stars value={score?.score || 4 }  />
                {/* <Stars value={score?.score} disabled={!score || !score.score} /> */}
                {score && score.votes > 0 ? <div className="c-card__votes">({score.votes})</div> : null}
              </div>
            </div>
            {/* EXPERIENCE */}
            {/* <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.experience.label', defaultMessage: 'Experience' })}
              </div>
              <div className="c-card__value">
                {intl.formatMessage(
                  {
                    id: 'components.card.experience.value',
                    defaultMessage: '{experience, plural, =1 {# appointment} other {# appointments}}',
                  },
                  { experience: experience || 0 }
                )}
              </div>
            </div> */}
            {/* DAYS */}
            {
              days && (
                <div className="c-card__item">
                <div className="c-card__key">
                  {intl.formatMessage({ id: 'components.card.worker-days.label', defaultMessage: 'Possible days' })}
                </div>
                <div className="c-card__value">
                  {days.map(
                    (d, index) =>
                      `${intl.formatMessage({ id: `days.short.${d}` })}${index !== days.length - 1 ? ', ' : ''}`
                  )}
                </div>
              </div>
              )
            }
            {/* LAST ONLINE */}
            <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.last-online.label', defaultMessage: 'Last online' })}
              </div>
              <div className="c-card__value">
                <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} />
              </div>
            </div>
            {/* LOCATION */}
            {/* <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.location.label', defaultMessage: 'Location' })}
              </div>
              <div className="c-card__value">{[address?.city, address?.country].filter(Boolean).join(', ')}</div>
            </div> */}
            {/* PERSONAL NOTE */}
            <div className="c-card__item c-card__item--last">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.personal-note.label', defaultMessage: 'Personal note' })}
              </div>

              <div className="c-card__value c-card__value--highlight">{truncatedDescription}</div>
            </div>
            {/* VIEW BUTTON */}
            {showViewButton && (
              <Button className="c-btn c-card__button" onClick={onView}>
                {intl.formatMessage({ id: 'components.card.view', defaultMessage: 'View' })}
              </Button>
            )}
          </div>
          <link itemProp="url" href={`/worker/${personId}`} />
          <meta itemProp="availability" content="https://schema.org/InStock" />
          <meta itemProp="price" content={price ? price.amount * 0.01 : null} />
          <meta itemProp="priceCurrency" content={price ? price.currency : null} />
          <meta itemProp="priceValidUntil" content={moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')} />
          {score && score.score && score.votes > 0 ? (
            <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating" itemScope>
              <meta itemProp="reviewCount" content={score.votes} />
              <meta itemProp="ratingValue" content={score.score} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

WorkerCard.propTypes = {
  personId: PropTypes.string.isRequired,
  className: PropTypes.string,
  score: PropTypes.shape({ votes: PropTypes.number, score: PropTypes.number }),
  price: PropTypes.shape({ currency: PropTypes.string, amount: PropTypes.number }),
  address: PropTypes.shape({ city: PropTypes.string, country: PropTypes.string }),
  experience: PropTypes.number,
  firstName: PropTypes.string,
  profilePicture: PropTypes.shape({ thumbnail: PropTypes.string, large: PropTypes.string }),
  verification: PropTypes.bool,
  lastLogin: PropTypes.number,
  isOnline: PropTypes.bool,
  personalNote: PropTypes.string,
  showViewButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  showFavorite: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onView: PropTypes.func,
  onFavorite: PropTypes.func,
};

WorkerCard.defaultProps = {
  className: '',
  score: {},
  price: {},
  address: {},
  experience: 0,
  firstName: '',
  profilePicture: '',
  verification: false,
  lastLogin: 0,
  isOnline: false,
  personalNote: '',
  showViewButton: false,
  showCloseButton: false,
  onClose: () => {},
  showFavorite: false,
  isFavorite: false,
  onView: () => {},
  onFavorite: () => {},
};

export default WorkerCard;
